/***justappt CSS***/
/*** Author: Sourav Saha***/
@media screen and (max-width:1600px) {
    html {
        font-size: 1rem !important;
    }
}

@media screen and (max-width:1550px) {
    html {
        font-size: 0.95rem !important;
    }
}

@media screen and (max-width:1500px) {
    html {
        font-size: 0.9rem !important;
    }
}

@media screen and (max-width:1400px) {
    html {
        font-size: 0.85rem !important;
    }

    html,
    body {
        height: 100% !important;
        overflow-x: hidden;
    }

    /* .table-container {
        width: 100%;
        overflow: auto;
        position: relative;
    } */
}

@media screen and (max-width: 820px) {
    /* .table-container {
        width: 500px;
        overflow: auto;
        position: relative;
    } */
}

@media screen and (max-width: 440px) {

    /** login page**/
    .loginPageMainSection .loginFormWrapper {
        width: 90%;
        margin: 0px auto;
        padding: 1.5rem;
        right: 0;
        left: 0;
    }

    .loginFormWrapper .heading {
        width: 49%;
        margin-left: 5.5rem;
        margin-right: 5.5rem;
        /* width:calc(100% - 20px); */
        /* float: none; */

    }

    /* .full_width {
        float: none;
        
    } */
    /** Dashboard Page**/
    /** logo **/
    /* .leftMenuContainer .logoContainer .logoImage {
        display:none;
    } */
    /** Dashboard header**/
    .headerContainer {
        width: 100%;
        left: 0;
        top: 0;
        right: 0;
    }

    .BtnFullScreenMode img {
        width: 2.625rem;
        position: relative;
        top: 10px;
    }

    .menu-icon img {
        /* width: 6.625rem; */
    }

    .crmProtectiveContainer.collaps {
        width: 100%;
    }

    .headerContainer.collaps {
        left: 0px;
        width: 100%;
    }

    .headerContainer .headerTop .headerTopRight {
        width: 50%;
        padding: 1rem 0 0 1rem;
    }

    .headerContainer .headerTop .headerTopLeft {
        width: 50%;
        padding: 0.5rem 1rem 0.6rem 1rem;
    }

    .leftMenuContainer {
        width: 0%;
        z-index: 100;
        transition: all .20s ease-in-out;
    }

    /* .leftMenuContainer.hide {
        width: 0px;
        transition: all .20s ease-in-out;
    } */

    .leftMenuContainer.collaps {
        width: 100%;
        transition: all .20s ease-in-out;
    }

    .leftMenuContainer.collaps .menuControlAction .displayText {
        display: block;
    }

    .leftMenuContainer.collaps .menuContainer li .displayTitle {
        display: block;
    }

    .leftMenuContainer.collaps .leftSubMenuItemInner .displaySubTitle {
        display: block;
    }

    .leftMenuContainer .topSection {
        padding: 0;
        overflow-x: hidden;
        width: 90%;
        margin: 10px 20px;
    }

    .headerTopLeft {
        display: block;
    }

    .res-hide {
        display: none;
    }

    .res-show {
        display: block;
    }

    .res-flex {
        display: flex;
        align-items: center;
        /* gap: 5rem; */
        justify-content: end;
    }

    .res-profile-container {
        width: 40%;
        /* height: 60px; */
        background: #FFFFFF;
        border-radius: 5px;
        /* float: right; */
        margin: 0 13px;
        position: absolute;
        right: 0;
        top: 3.4rem;
        padding: .5rem;
        /* display: flex; */
        /* text-align: center;
        justify-content: center; */
        box-shadow: 0px 8px 16px 0px rgba(149, 153, 150, 0.2);
    }

    .designation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }

    .btn-logout {
        width: 100%;
        color: #FFF;
        font-weight: 500;
        font-size: 1.1rem;
        background-color: #EE7004;
        padding: .7rem 0;
        margin-top: 1rem;
        box-shadow: 0px 1px 10px 0px rgba(149, 153, 150, 0.2);
        border-radius: 8px;
        /* cursor: pointer; */
    }

    .menu-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .7rem;
        margin-left: .4rem;
        padding: .5rem 0 0 0rem;
        height: 50px;
    }

    .left-menu {
        display: flex;
        align-items: center;
    }

    /* .logo-icon img {
        display: flex;
        align-items: center;
    } */

    /** Dashboard top blog section**/
    .dashBoardMainSection .dashBoardLeftSection_full {
        width: 100%;
        padding: 0 10px;
    }

    .dashBoardMainSection .infoIconBlock {
        margin: 0 0.3125rem 0.3125rem 0;
    }

    .panels {
        display: block;
    }

    /** Dashboard right-side**/
    .crmProtectiveContainer {
        width: 100%;
    }

    .dashBoardMainSection .dashBoardLeftSection_full .label_card_container {
        /* display:block; */
        display: grid;
        /* grid-template-columns: repeat(4, 24.21%); */
        grid-template-columns: repeat(2, 1fr);
        gap: .5rem;
    }

    /* .componentSubHeading {
        position: absolute;
    } */
    .blog {
        position: absolute;
    }

    .one_half {
        width: 100%
    }

    .marT_24 {
        margin-left: 20px;
    }

    .dashBoardMainSection .highChartMainSection .leftSec_full {
        padding-left: 0;
        margin-left: 0.4rem;
    }

    .tableRowContainer .query_id_col {
        width: 66%;
    }

    .res-dashboard {
        width: 562px;

    }

    /** customer details**/
    .tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn .btnWrapper {
        padding: 0.85rem 2.8rem 0.75rem 2.75rem;
    }

    .headingFilterActionContainer .leftSec {
        width: 100%;
    }

    .componentNormHeading {
        font-size: 1.3rem;
        font-weight: 500;
    }

    .createAdmissionMainSection .filterFormContainer .formFieldContainer {
        width: 24rem;
    }

    .one_fourth,
    .two_third,
    .one_third {
        width: 100%;

    }

    .pageInnerMainSection {
        padding: 10px;
    }

    .paginationActionWrapper .actionBtn img {
        width: 2.6rem;
        padding-right: 7px;
    }

    .paginationActionWrapper .actionBtn {
        width: 2rem;
        height: 2rem;
        margin-left: 0.8rem;
        margin-right: 2px;
    }

    .button_group {
        justify-content: center;
    }

    /* .tableMainContainer {
        overflow-x:auto;
    } */

    /* .tableMainContainer {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        border: 1px solid #ddd;
    } */
    /** lead + query managment **/
    .one_fifth {
        width: 100%;
    }

    .dialog {
        width: 90%;
    }

    .dialog_header {
        padding: 10px 10px;
        border-radius: 0.940rem 0.940rem 0 0;
    }

    .dialog .pageInnerMainSection {
        border-radius: 0 0 0.940rem 0.940rem;
    }

    .dialog_header .heading {
        margin-left: 7px;
    }

    .dialog img._close_popup {
        margin-right: 4px;
    }

    .generalFormSectionMain {
        padding: 10px 0;
    }

    /* .textField {
        padding-left: 4px;
        padding-right: 4px;
    } */
    .tableMainSection .tableMainContainer .tableRowContainer .tableColumn {
        padding: 0 1px;
    }

    .res-customer {
        width: 900px;

    }

    /** lead **/
    .res-lead {
        width: 900px;

    }

    .res-query-plan {
        width: 480px;

    }

    /** query**/
    .table-container {
        width: 394px;
        overflow: auto;
        position: relative;
    }

    .res-query {
        width: 900px;

    }

    .tableMainSection .tableMainContainer .tableRowContainer .fixed-content {
        /* padding: 0; */
        /* position: sticky; */
        right: 0;
        background-color: white;
    }

    /* .tableRowContainer .tableColumn.actionCenter .action-dropdown {
        z-index: 1;
    } */
    /* .action-dropdown-list {
        position: sticky;
    }  */

}

@media screen and (max-width: 380px) {

    /** login **/
    .loginFormWrapper .heading {
        width: 55%;
        margin-left: 4.5rem;
    }

    /** customer **/
    .tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn .btnWrapper {
        padding: 0.85rem 1.4rem 0.85rem 2.78rem;
    }

    /** query **/
    .table-container {
        width: 100%;
        overflow: auto;
        position: relative;
    }

    /* .res-testing {
        width: 562px;

    } */
}