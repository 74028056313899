/***justappt CSS***/
/*** Author: Sourav Saha***/
.headerContainer {
    width: calc(100% - 224px);
    height: 7.125rem;
    position: fixed;
    left: 224px;
    top: 0;
    background-color: #F7F9FD;
    border-bottom: 1px solid #E5E9F1;
    z-index: 1;
}

.headerContainer.collaps {
    left: 88px;
    width: calc(100% - 88px);
}

.headerContainer .headerTop {
    padding-bottom: 0.875rem;
}

.headerContainer .headerTop .headerTopLeft {
    width: 50%;
    padding: 1.25rem 1rem 0 3rem;

}

.res-hide {
    display: block;
}

.res-show {
    display: none;
}

.headerContainer .headerTop .headerTopRight {
    width: 50%;
    padding: 1.25rem 2rem 0 1rem;
}

.headerContainer .spclAppSearchField {
    margin-left: 0.5rem;
}

.headerTopRight .btnAction {
    margin: 0 0.625rem;
    height: 2rem;
}

.headerTopRight .btnAction.btnNormAction {}

.headerTopRight .appSelectNoBorder {
    height: 2rem;
}

.headerTopRight .btnAction img {
    width: 1.25rem;
}

.headerTopRight .btnAction.sessionBtn {
    margin-left: 4.5rem;
}

.headerTopRight .profileInfoContainer .profileImage {
    width: 2.125rem;
    float: left;
    border-radius: 50%;
}

.headerTopRight .profileInfoContainer .profileInfo {
    width: calc(100% - 2.125rem);
    float: left;
    padding-left: 0.625rem;
    padding-right: 3rem;
    background-image: url(../images/icon/dropdown_arrow_icon.svg);
    background-position: right 0 center;
    background-size: 0.75rem auto;
}

.headerTopRight .profileInfoContainer .actionArrow {
    /*width: 0.75rem;
    float: left;*/
}

.headerTopRight .profileInfoContainer .profileInfo .name {
    color: #191E35;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}

.headerTopRight .profileInfoContainer .profileInfo .role {
    color: #8C9099;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-top: 0.125rem;
}

.headerContainer .headerBottom {
    padding-left: 3rem;
}

.headerPageItemContainer .headerPageItem {
    padding-right: 0.75rem;
    margin-right: 0.75rem;
}

.headerPageItemContainer .headerPageItem a {
    color: #6C6F75;
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1.125rem;
    /* 138.462% */
    margin-right: 0.875rem;
    padding-bottom: 1rem;
    float: left;
}

.headerPageItemContainer .headerPageItem.itemPageActive a {
    color: #004DF6;
    font-weight: 600;
    background-position: center bottom 0;
    background-image: url(../images/icon/header_page_selection_icon.svg);
    background-size: 2.5rem auto;
    background-repeat: no-repeat;
}

.desk-profile-container {
    width: 15%;
    height: 100px;
    background:#5aac8a;
    border-radius: 5px;
    float: right;
    margin: 0 13px;
    position: absolute;
    right: 0;
    top: 4.3rem;
    display: flex;
    text-align: center;
    justify-content: center;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.show-profile-dd {
    display:block;
}
.hide-profile-dd {
    display:none;
}
.btn-logout {
    /* padding: 40px 40px; */
    color:white;
    font-weight: 600px;
     /* font-size: 1.2rem; */
     /* font-weight: bold; */
     /* background-color: antiquewhite; */
     /* color:rgb(46, 41, 41); */
     font-size: 90%;
     
}

.res-profile-container {
    width: 10rem;
    /* height: 60px; */
    background: #FFFFFF;
    border-radius: 5px;
    /* float: right; */
    margin: 0 13px;
    position: absolute;
    right: 1rem;
    top: 4rem;
    padding: .5rem;
    /* display: flex; */
    /* text-align: center;
    justify-content: center; */
    box-shadow: 0px 8px 16px 0px rgba(149, 153, 150, 0.2);
}
.designation {
    display:none;
}

.btn-logout {
    width: 100%;
    color: #FFF;
    font-weight: 500;
    font-size: 1.1rem;
    background-color: #EE7004;
    padding: .7rem 0;
    margin-top: .3rem;
    box-shadow: 0px 1px 10px 0px rgba(149, 153, 150, 0.2);
    border-radius: 8px;
    /* cursor: pointer; */
}

