/***justappt CSS***/
/*** Author: Sourav Saha***/
.crmProtectiveContainer {
    width: calc(100% - 224px);
    float: right;
}

.crmProtectiveContainer.collaps {
    width: calc(100% - 88px);
}

.crmProtectiveContainer .innerMainContainer {
    padding-top: 7.125rem;
    height: auto;
}



.componentNormHeading {
    color: #191E35;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: normal;
}

.componentSubHeading {
    color: #191E35;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}

/*.loginPageMainSection {
    background-repeat: no-repeat;
    background-image: url(../images/static/login_background.svg);
    background-size: cover;
    background-position: center;
}*/

.loginPageMainSection .loginFormWrapper {
    width: 30rem;
    background-color: #FFF;
    border-radius: 2rem;
    border: 0.625rem solid #06699B;
    position: absolute;
    left: 12.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    padding: 3rem 2.5rem 2rem 2.5rem;
}

.loginFormWrapper .imageContainer {}

.loginFormWrapper .logoImage {
    width: 8rem;
}

.loginFormWrapper .heading {
    color: #000;
    text-align: center;
    font-size: 1.625rem;
    font-weight: 600;
    line-height: normal;
    margin-top: 1.5rem;
}

.loginFormWrapper .formContainer {
    margin-top: 2.5rem;
}

.loginFormWrapper .actionBtn {
    background-color: #004DF6;
    border-radius: 0.625rem;
    color: #fff;
    font-size: .9375rem;
    font-weight: 600;
    letter-spacing: .05em;
    line-height: 1.125rem;
    padding: 1.125rem;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    margin-top: 1rem;
}

.loginFormWrapper .bottomContainer {
    margin-top: 4rem;
}

.loginFormWrapper .bottomContainer .txtContent {
    color: #979BA2;
    font-size: 0.875rem;
    line-height: normal;
}

.loginFormWrapper .bottomContainer .txtContent button {
    color: #004DF6;
    font-size: 14px;
    line-height: normal;
    margin-left: 0.25rem;
}



/***Dashboard***/
.dashBoardMainSection .dashBoardLeftSection {
    width: calc(100% - 20.625rem);
    padding: 1.875rem 3rem;
}

.dashBoardMainSection .basicTopInfoSec {
    margin-top: 1.125rem;
}

.dashBoardMainSection .infoIconBlock {
    width: 11.25rem;
    height: 6.25rem;
    background-color: #fff;
    border-radius: 1.125rem;
    box-shadow: 0px 4px 10px rgba(228, 229, 232, 0.20);
    border: 1px solid #EFF2F8;
    padding: 0 1.125rem;
    float: left;
}

.dashBoardMainSection .infoIconBlock .circleIconBlock {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.dashBoardMainSection .infoIconBlock.student .circleIconBlock {
    background-color: #EAF1FF;
}

.dashBoardMainSection .infoIconBlock.employee .circleIconBlock {
    background-color: #D8FFFD;
}

.dashBoardMainSection .infoIconBlock.teacher .circleIconBlock {
    background-color: #FFF3DB;
}

.dashBoardMainSection .infoIconBlock.parent .circleIconBlock {
    background-color: #F0E3FF;
}


.dashBoardMainSection .infoIconBlock.active .circleIconBlock {
    background-color: #FFFFFF !important;
}



.dashBoardMainSection .infoIconBlock.student {
    margin: 0 0.3125rem 0.3125rem 0;
}

.dashBoardMainSection .infoIconBlock.employee {
    margin: 0 0 0.3125rem 0.3125rem;
}

.dashBoardMainSection .infoIconBlock.teacher {
    margin: 0.3125rem 0.3125rem 0 0;
}

.dashBoardMainSection .infoIconBlock.parent {
    margin: 0.3125rem 0 0 0.3125rem;
}





.dashBoardMainSection .infoIconBlock.student.active {
    background-color: #004DF6;
}

.dashBoardMainSection .infoIconBlock.employee.active {
    background-color: #06BAB2;
}

.dashBoardMainSection .infoIconBlock.teacher.active {
    background-color: #DD9707;
}

.dashBoardMainSection .infoIconBlock.parent.active {
    background-color: #7E1DEE;
}



.dashBoardMainSection .infoIconBlock .circleIconBlock img {
    width: 1.25rem;
}

.dashBoardMainSection .infoIconBlock .infoBlock {
    width: calc(100% - 3rem);
    padding-left: 0.875rem;
}

.dashBoardMainSection .infoIconBlock .infoBlock .title {
    color: #191E35;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: normal;
}

.dashBoardMainSection .infoIconBlock .infoBlock .value {
    color: #7B7F83;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.125rem;
    /* 138.462% */
    letter-spacing: 0.13px;
}

.dashBoardMainSection .infoIconBlock.active .infoBlock .title {
    color: #FFF;
}

.dashBoardMainSection .infoIconBlock.active .infoBlock .value {
    color: #FFF;
}

.dashBoardMainSection .basicTopInfoSec .leftSec {
    width: 100%;
    /*23.75rem;*/
}

.dashBoardMainSection .basicTopInfoSec .rightSec {
    width: calc(100% - 24.25rem);
    background-color: #ECF2FF;
    border-radius: 0.625rem;
    padding: 1.5rem 1.75rem;
    position: relative;
}

.dashBoardMainSection .basicTopInfoSec .rightSec .rightInfoSec {
    width: calc(100% - 14.75rem);
}

.dashBoardMainSection .basicTopInfoSec .rightSec .rightGraphSec {
    width: 14.75rem;
}

.dashBoardMainSection .basicTopInfoSec .rightSec .monthTitle {
    color: #707A90;
    font-size: 0.875rem;
    font-style: normal;
    line-height: 1.5rem;
    /* 171.429% */
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.dashBoardMainSection .basicTopInfoSec .rightSec .headCompare {
    color: var(--dmv-black, #23262F);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.5rem;
    /* 200% */
}

.dashBoardMainSection .basicTopInfoSec .rightSec .infoRow {
    margin: 0.375rem 0;
}

.dashBoardMainSection .basicTopInfoSec .rightSec .calculationInfo .infoRowTitle {
    color: #2F3036;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.125rem;
    /* 246.154% */
    width: 9rem;
    background-repeat: no-repeat;
    background-position: left 0 center;
    background-size: 0.625rem auto;
    padding-left: 1.25rem;
}

.dashBoardMainSection .basicTopInfoSec .rightSec .income .infoRowTitle {
    background-image: url(../images/icon/green_dot_icon.svg);
}

.dashBoardMainSection .basicTopInfoSec .rightSec .expense .infoRowTitle {
    background-image: url(../images/icon/orange_dot_icon.svg);
}

.dashBoardMainSection .basicTopInfoSec .rightSec .calculationInfo .infoRowVal {
    color: #191E35;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.125rem;
    /* 120% */
}

.dashBoardMainSection .basicTopInfoSec .rightSec .profit {
    padding-top: 0.5rem;
    border-top: 1px solid #D4DBE9;
    width: auto;
}

.dashBoardMainSection .basicTopInfoSec .rightSec .profit .infoRowTitle {
    color: #000;
    font-weight: 500;
}

.dashBoardMainSection .basicTopInfoSec .rightGraphSec {
    position: relative;
}

.dashBoardMainSection .basicTopInfoSec .rightGraphSec .plotImage {
    width: 100%;
    margin-top: 1.875rem;
}

.dashBoardMainSection .basicTopInfoSec .rightGraphSec .heading {
    width: 100%;
    text-align: center;
    position: absolute;
    color: #000;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: normal;
    bottom: 0.5rem;
    left: 0;
}

.dashBoardMainSection .basicTopInfoSec .rightSec .actionBtnSec {
    position: absolute;
    right: 0.875rem;
    top: 0.75rem;
}

.dashBoardMainSection .highChartMainSection {
    margin-top: 1.25rem;
}

.dashBoardMainSection .highChartMainSection .leftSec {
    width: calc(100% - 12.5rem);
    float: left;
    box-shadow: 0px 4px 10px rgba(228, 229, 232, 0.20);
    background-color: #fff;
    border: 1px solid #EFF2F8;
    border-radius: 1rem;
    padding: 1.5rem;
}

.dashBoardMainSection .highChartMainSection .componentSubHeading {
    margin-left: 1rem;
}

.dashBoardMainSection .highChartMainSection .infoIconBlock {
    margin: 0.3125rem 0.625rem;
}

.dashBoardMainSection .highChartMainSection .infoIconBlock:first-child {
    margin-top: 0;
}

.dashBoardMainSection .varityPlotInfoBlock {
    margin-top: 1.25rem;
    width: 100%;
    float: left;
    box-shadow: 0px 4px 10px rgba(228, 229, 232, 0.20);
    background-color: #fff;
    border: 1px solid #EFF2F8;
    border-radius: 1rem;
    padding: 1.5rem;
    min-height: 25rem;
}

.dashBoardMainSection .varityPlotInfoBlock .actionBtnCenter {
    width: 12.125rem;
    height: 2.25rem;
    background-color: #F1F5FC;
    float: left;
    border-radius: 0.375rem;
}

.dashBoardMainSection .varityPlotInfoBlock .componentSubHeading {
    width: calc(100% - 12.125rem);
    padding-right: 0.75rem;
    float: left;
    border-radius: 0.375rem;
}

.dashBoardMainSection .varityPlotInfoBlock .dataDisplayActionBtn {
    color: #93959C;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    /* 138.462% */
    padding: 0.3125rem 0.5rem;
    margin: 0 0.375rem;
    cursor: pointer;
}

.dashBoardMainSection .varityPlotInfoBlock input[type=radio]:checked+.dataDisplayActionBtn {
    background-color: #FFF;
    box-shadow: 0px 0px 4px rgba(155, 161, 172, 0.30);
    color: #2F3036;
    font-weight: 600;
    border-radius: 0.5rem;
}

.highChartMainSection .dashBoardSelect {
    float: right;
    width: 8.875rem;
    height: 1.875rem;
    background-color: #FBFCFF;
    border: 1px solid #ECF1FC;
    color: #2F3036;
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1.125rem;
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-repeat: no-repeat;
    background-position: right 0.625rem center;
}

.dashBoardMainSection .dashBoardRightSection {
    width: 20.625rem;
    border-left: 1px solid #EAEFF8;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.dashBoardMainSection .dashBoardRightSection .headActionBtnCenter {
    margin-top: 1rem;
}

.dashBoardMainSection .dashBoardRightSection .dataDisplayActionBtn {
    color: #6C6F75;
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.125rem;
    /* 120% */
    cursor: pointer;
    padding-bottom: 0.5rem;
    margin: 0 0.75rem;
}

.dashBoardMainSection .dashBoardRightSection input[type=radio]:checked+.dataDisplayActionBtn {
    background-position: center bottom 0;
    background-image: url(../images/icon/chk_bottom_strike_icon.svg);
    background-size: 1.25rem auto;
    background-repeat: no-repeat;
    color: #004DF6;
    font-weight: 600;
}

.dashBoardMainSection .dataListContainer {
    margin-top: 1.875rem;
}

.dashBoardMainSection .dataListContainer .recordRow {
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
    border-bottom: 1px solid #EBEFF6;
}

.dashBoardMainSection .dataListContainer .recordColumn {
    display: block;
    float: left;
    text-align: center;
    color: #191E35;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
    /* 228.571% */
}

.dashBoardMainSection .dataListContainer .recordColumn.verticle_aligned_element {
    display: flex;
}

.dashBoardMainSection .dataListContainer .recordColumn .titleVal {
    padding-left: 0.625rem;
}



/***end of dashboard***/

/**Branch css***/


.pageInnerMainSection {
    background-color: #F7F9FD;
    padding: 1.5rem 3rem;
    border-radius: 1.875rem;
}

.pageInnerMainSection .tabContentSection {
    display: none;
}

.tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn {
    display: inline-block;
}

.tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn .btnWrapper {
    padding: 0.75rem 1.5rem 0.75rem 2.75rem;
    background-color: #fff;
    border: 1px solid #E0E3EA;
    border-radius: 0.625rem;
    color: #1E1E1E;
    font-size: 0.875rem;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    margin-right: 0.625rem;
    background-repeat: no-repeat;
    background-position: left 1rem center;
    background-size: 1rem auto;
}

.tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn.dataDisplayBtn .btnWrapper {
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
}

.tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn.list .btnWrapper {
    background-image: url(../images/icon/tab_list_btn_icon.svg);
}

.tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn.addForm .btnWrapper {
    background-image: url(../images/icon/tab_add_btn_icon.svg);
}

.tabSectionContainer .radioBtnSpecial input[type=radio]:checked+.dataDisplayActionBtn .btnWrapper {
    background-color: #004DF6;
    color: #FFF;
    font-weight: 600;
}

.tabSectionContainer .radioBtnSpecial input[type=radio]:checked+.dataDisplayActionBtn.list .btnWrapper {
    background-image: url(../images/icon/tab_list_btn_selected_icon.svg);
}

.tabSectionContainer .radioBtnSpecial input[type=radio]:checked+.dataDisplayActionBtn.addForm .btnWrapper {
    background-image: url(../images/icon/tab_add_btn_selected_icon.svg);
}

.tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn.admin .btnWrapper {
    background-image: url(../images/icon/tab_admin_icon.svg);
}

.tabSectionContainer .radioBtnSpecial input[type=radio]:checked+.dataDisplayActionBtn.admin .btnWrapper {
    background-image: url(../images/icon/tab_admin_selected.svg);
}

.tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn.teacher .btnWrapper {
    background-image: url(../images/icon/tab_teacher_icon.svg);
}

.tabSectionContainer .radioBtnSpecial input[type=radio]:checked+.dataDisplayActionBtn.teacher .btnWrapper {
    background-image: url(../images/icon/tab_teacher_selected_icon.svg);
}

.tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn.accountant .btnWrapper {
    background-image: url(../images/icon/tab_accountant_icon.svg);
}

.tabSectionContainer .radioBtnSpecial input[type=radio]:checked+.dataDisplayActionBtn.accountant .btnWrapper {
    background-image: url(../images/icon/tab_accountant_selected_icon.svg);
}

.tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn.librarian .btnWrapper {
    background-image: url(../images/icon/tab_librarian_icon.svg);
}

.tabSectionContainer .radioBtnSpecial input[type=radio]:checked+.dataDisplayActionBtn.librarian .btnWrapper {
    background-image: url(../images/icon/tab_librarian_selected_icon.svg);
}

.tabSectionContainer .radioBtnSpecial .dataDisplayActionBtn.receptionist .btnWrapper {
    background-image: url(../images/icon/tab_receptionist_icon.svg);
}

.tabSectionContainer .radioBtnSpecial input[type=radio]:checked+.dataDisplayActionBtn.receptionist .btnWrapper {
    background-image: url(../images/icon/tab_receptionist_selected_icon.svg);
}

.headingFilterActionContainer .leftSec {
    width: calc(100% - 36.25rem);
    float: left;
}

.headingFilterActionContainer .rightSec {
    width: 36.25rem;
    float: left;
}

.headingFilterActionContainer .spclAppSearchField {
    width: 21rem;
    height: 2.25rem;
    margin-right: 0.25rem;
    float: left;
}

.headingFilterActionContainer button.btnAction {
    width: 2.25rem;
    height: 2.25rem;
    margin-left: 0.25rem;
    float: left;
}

.headingFilterActionContainer button.btnAction img {
    width: 2.25rem;
}

.tableMainSection .tableMainContainer {
    border-radius: 1.5rem;
    margin-top: 1rem;
    /*position: relative;*/
}

.tableMainSection .tableMainContainer .tableRowContainer {
    background-color: #FFF;
    /*position: absolute; *
    z-index: 1;*/
    border-bottom: 1px solid #EFF2F8;
    box-shadow: 0px 4px 10px rgba(228, 229, 232, 0.20);
}

.tableMainSection .tableMainContainer .tableRowContainer .tableColumn {
    float: left;
    min-height: 4rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: #6B6E73;
    font-size: 0.875rem;
    line-height: normal;
    padding: 0 0.5rem;
}

.tableRowContainer .tableColumn .infoImage {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
}

.tableRowContainer .tableColumn .infoTitle {
    width: calc(100% - 2.25rem);
    padding-left: 0.5rem;
    color: #1E1E1E;
    font-size: 0.9375rem;
    line-height: normal;
}

.tableRowContainer .tableColumn .circleInfoBlock {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    background-color: #EBEFF6;
    color: #000;
}

.tableMainSection .tableMainContainer .tableRowContainer .tableColumn.classColumn {
    color: #004DF6;
    font-weight: 600;
}

.tableMainSection .tableMainContainer .tableRowContainer .tableColumn.phoneColumn {
    color: #6B6E73;
}

.tableMainSection .tableMainContainer .tableRowContainer .tableColumn.applyDate {
    color: #1E1E1E;
}

.tableRowContainer .tableColumn .statusDisplay {
    padding: 0.3125rem 1.25rem;
    border-radius: 1.25rem;
    text-align: center;
    font-size: 0.8125rem;
    line-height: normal;
}

.tableRowContainer .tableColumn .statusDisplay.active {
    background-color: #DCE7FF;
    border: 1px solid #DCE7FF;
    color: #004DF6;
}

.tableRowContainer .tableColumn .statusDisplay.deactive {
    background-color: #FFEFCD;
    border: 1px solid #FFE4AC;
    color: #CC8B05;
}

.tableRowContainer .tableColumn .paymentStatusDisplay {
    font-size: 0.8125rem;
    line-height: normal;
    background-repeat: no-repeat;
    background-position: left 0 center;
    background-size: 0.625rem auto;
    padding-left: 1.125rem;
}

.tableRowContainer .tableColumn .paymentStatusDisplay.complete {
    color: #02C6D2;
    background-image: url(../images/icon/complete_status_icon.svg);
}

.tableRowContainer .tableColumn .paymentStatusDisplay.pending {
    color: #FF4230;
    background-image: url(../images/icon/pending_status_icon.svg);
}

.tableRowContainer .tableColumn.actionCenter button {
    /*width: 2.25rem;
    height: 2.25rem;*/
    margin: 0.25rem;
}

.tableRowContainer .tableColumn .actionImage {
    width: 2.25rem;
}

.tableMainSection .tableMainContainer .tableHeaderContainer {
    border-top: 1px solid #EFF2F8;
    border-right: 1px solid #EFF2F8;
    border-left: 1px solid #EFF2F8;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    box-shadow: 0px 4px 10px rgba(228, 229, 232, 0.20);
}

.tableMainSection .tableMainContainer .tableHeaderContainer .tableColumn {
    min-height: 3rem;
    background-color: #F7F9FD;
    color: #6F7377;
    font-size: 0.75rem;
    line-height: normal;
    text-transform: uppercase;
}

.paginationActionWrapper {
    margin-top: 1.125rem;
}

.paginationActionWrapper .actionBtn {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.5rem;
}

.paginationActionWrapper .displayPageVal {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    border: 2px solid #CACDDD;
    background: #FFF;
    color: #000;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: normal;
}

.paginationActionWrapper .actionBtn img {
    width: 2.5rem;
}

.paginationActionWrapper .pageCount {
    margin-left: 0.75rem;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
}

.filterFormContainer {
    background-color: #ECF2FF;
    padding: 0.75rem 1.375rem 1.25rem 1.375rem;
    border-radius: 1rem;
    margin-top: 0.875rem;
}

.filterFormContainer .formFieldContainer {
    width: 19rem;
}

.filterFormContainer .formFieldContainer.actionBtnCenter {
    width: auto;
}

.filterFormContainer .formFieldContainer.actionBtnCenter button.actionBtn {
    width: 10rem;
    height: 3.125rem;
    background-color: #004DF6;
    border-radius: 0.625rem;
    color: #FFF;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.filterFormContainer .formFieldContainer.actionBtnCenter .label {
    opacity: 0;
}

.generalFormSectionMain {
    background-color: #fff;
    padding: 1.25rem 1.25rem;
    border-radius: 1rem;
    margin-top: 0.75rem;
}

.generalFormSectionMain .formSection {
    margin-top: 1.25rem;
}

.logoFileUploadWrapper .label {
    color: #004DF6;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.5rem !important;
    float: left;
}

.logoFileUploadContainer {
    border: 1.5px dotted #D5DAE5;
    padding: 2.5rem 3.25rem;
    background-color: #F7F9FD;
    position: relative;
    border-radius: 1rem;
    cursor: pointer !important;
    height: 12.5rem;
}

.logoFileUploadContainer .subHeading {
    color: #777C81;
    text-align: center;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.logoFileUploadContainer .heading {
    color: #191E35;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: normal;
    margin-top: 0.5rem;
}

.logoFileUploadContainer .stanza {
    color: #979A9F;
    text-align: center;
    font-size: 0.8125rem;
    line-height: normal;
    margin-top: 0.625rem;
}

.logoFileUploadWrapper .browsedLogoAppear {
    position: relative;
    border: 1.5px solid #D5DAE5;
    background-color: #FFF;
    border-radius: 1rem;
    height: 12.5rem;
}

.logoFileUploadWrapper .browsedLogoAppear .actionRemoveBtn {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    width: 1.75rem;
    height: 1.75rem;
}

.logoFileUploadWrapper .browsedLogoAppear .browsedImage {
    width: 60%;
}

.tableMainSection .tableMainContainer .tableRowContainer .tableColumn.progressBar,
.tableQueryMainSection .tableMainContainer .tableRowContainer .actionCenter {
    display: block;
}

.tableMainSection .tableMainContainer .tableRowContainer .tableColumn.progressBar span {
    display: none !important;
}

.tableMainSection .tableMainContainer .tableRowContainer .tableColumn .progressBarWrapper {
    margin-top: 1.5rem;
}

.tableMainSection .tableMainContainer .tableRowContainer .tableColumn .progressBarWrapper div {
    height: 10px !important;
}

.studentListMainSection .sysAppActBtn.deleteBtn {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    text-transform: unset;
}

.generalFormSectionMain .formAccordion {
    width: 2rem;
    height: 2rem;
    background-color: #FBFCFF;
    border: 1px solid #ECF1FC;
    border-radius: 0.625rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.75rem auto;
    background-image: url(../images/icon/up_dir_arrow_icon.svg);
}

.generalFormSectionMain .formAccordion.closed {
    background-image: url(../images/icon/down_dir_arrow_icon.svg);
}

.generalFormSectionMain .formSection.closed {
    display: none;
}

.admissionCategoryMainSection .tableColumn .circleInfoBlock {
    width: auto;
    padding: 0.375rem 1.25rem;
    height: auto;
    border-radius: 1rem;
}

.admissionCategoryMainSection .tableColumn.slNumber {
    padding-left: 1.5rem !important;
}

.admissionCategoryMainSection .tableHeaderContainer .tableColumn.slNumber {
    padding-left: 1.5rem;
}

.pageInnerMainSection.admissionCategoryMainSection {
    position: relative;
}

.pageInnerMainSection .pageInnerLeftSection {
    width: calc(100% - 23.75rem);
}

.pageInnerMainSection .pageInnerRightSection {
    position: absolute;
    width: 23.75rem;
    top: 0;
    right: 0;
    background-color: #fff;
    height: 100%;
    padding: 1rem;
}

.genericInnerMainSection .pageInnerRightSection {
    top: 145px;
}

.addItemHeading {
    color: #191E35;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    /* 133.333% */
    background-repeat: no-repeat;
    background-position: left 0 center;
    background-size: 1.5rem auto;
    background-image: url(../images/icon/add_square_icon.svg);
    padding-left: 2rem;
}

.pageInnerMainSection.admissionCategoryMainSection .pageInnerRightSection .formSection {
    margin-top: 1.5rem;
}

.createAdmissionMainSection .filterFormContainer .formFieldContainer {
    width: 25rem;
}

.leftSidedFormSectionMain {
    width: 28.125rem;
}

.rightSideInstruction {
    width: calc(100% - 29.5rem);
    margin-left: 1.375rem;
    background-color: #ECF2FF;
    padding: 1.5rem 2.25rem;
    border-radius: 1rem;
    position: relative;
    padding-bottom: 16.25rem;
}

.admissionMultipleImport .generalFormSectionMain {
    margin-top: 0;
}

.admissionMultipleImport .middleBodySection {
    margin-top: 1.25rem;
}

.rightSideInstruction .orderedList {
    margin-top: 1.25rem;
    padding-left: 0.875rem;
}

.rightSideInstruction .orderedList li {
    list-style: auto;
    color: #606266;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
    margin-bottom: 0.625rem;
    padding-left: 0.625rem;
}

.rightSideInstruction .orderedList li::marker {
    color: #000;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
    margin-right: 0.625rem;
}

.rightSideInstruction .instructionBackInfo {
    position: absolute;
    width: 20rem;
    bottom: 0;
    right: 2rem;
}

.rightSideInstruction .instructionBackHouse {
    position: absolute;
    width: 30.5rem;
    bottom: 1.5rem;
    left: 0;
}

.tabContentInnerSection {
    position: relative;
}

.tabContentSuperMainSec {
    margin-top: 1.25rem;
}

.listFormSameLayout {
    position: relative;
    padding-left: 0;
    padding-right: 0;
}

.pageInnerMainSection.listFormSameLayout .pageInnerLeftSection {
    width: calc(100% - 26rem);
}

.pageInnerMainSection.listFormSameLayout .pageInnerRightSection {
    margin-top: 0.5rem;
    position: unset;
    height: auto;
    float: right;
    padding-bottom: 2rem;
}

.fileUploadReviewContainer {
    background-color: #F7F9FD;
    border: 1.5px dashed #D5DAE5;
    border-radius: 0.625rem;
    display: block;
    height: 100%;
    float: left;
    padding: 1.5rem 1rem 1.125rem 1rem;
}

.fileUploadReviewContainer .imageReviewed {
    width: 10rem;
    height: 10rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.fileUploadReviewContainer .fileBrowsed {
    color: #191E35;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}

.fileUploadReviewContainer .prevBtn {
    padding: 1rem;
    color: #004DF6;
    text-align: center;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: normal;
}

/*.pageInnerMainSectionItinerary .tableColumn.actionCenter{
    display: block !important;
}
.pageInnerMainSectionItinerary .tableColumn.actionCenter button{
    float: left;
    width:100%;
    text-align: left;
    margin-top: 10px;
}*/
/**	Table Action **/
.tableRowContainer .tableColumn.actionCenter .action-dropdown {
    position: relative;
    display: flex;
    bottom: 1rem;
}

button.action-dropdown-button {
    background: url(../images/icon/dropdown_arrow_icon.svg) right 1rem center #EBEFF6 no-repeat;
    font-weight: 500;
    font-size: 14px;
    padding: .5rem 2.4rem .5rem 1.2rem;
    color: #8D95A0;
    border-radius: .3rem;
    border: none;
    outline: none;
    cursor: pointer;
}

.action-dropdown-list {
    background: #fff;
    min-width: 10rem;
    padding: .625rem;
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: .3rem;
    z-index: 1;
}

.action-dropdown-list.appeared {
    display: block !important;
}

.action-dropdown-icon-list{
    min-width: 12rem;
}

.action-dropdown-list button {
    width: calc(100% - 0.5rem);
    text-align: left;
}

.__react_component_tooltip,
.__react_component_tooltip.place-top::before {
    background: none !important;
}

/**	eof Table Action **/

/**	Login **/
.loginPageMainSection {
    background: #EE7004;
}

/**	eof Login **/

/**	Query Management **/
.viewColumn {
    cursor: pointer;
}

.formDescSection {
    width: 30%;
}

.formDescLargeSection {
    width: 60%;
}

.formDescLastSection {
    width: 10%;
}

/**	eof Query Management **/
.tox .tox-editor-container {
    z-index: 0;
}

.label-hd {
    color: #6F7377;
    font-size: 0.75rem;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0.3125rem;
}