/* styling for spinner */
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .4rem 0;
}

/* ----------------- new style for dashboard ----------------- */

.dashBoardMainSection .infoIconBlock .infoBlock .value2 {
    color: #A4B4C1;
    font-size: 0.775rem;
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.dashBoardMainSection .infoIconBlock.active .infoBlock .value2 {
    color: #FFF;
}

.panels {
    padding-top: 1.2rem;
    width: 100%;
    display: flex;
    gap: 10px;
    overflow: hidden;
}

.panels .chartWrapper {
    width: 49%;
    box-shadow: 0px 4px 10px rgba(228, 229, 232, 0.20);
    background-color: #fff;
    border: 1px solid #EFF2F8;
    border-radius: 1rem;
    padding: 1.5rem 1rem;
}

.ml-3 {
    margin-left: .935rem;
}

.dashBoardMainSection .dashBoardLeftSection_full {
    width: 100%;
    padding: 1.875rem 3rem;
}

.dashBoardMainSection .dashBoardLeftSection_full .label_card_container {
    display: grid;
    /* grid-template-columns: repeat(4, 24.21%); */
    grid-template-columns: repeat(5, 19.28%);
    gap: .5rem;
}

.dashBoardMainSection .infoIconBlock.labelCard {
    box-shadow: 0px 4px 10px rgba(13, 59, 196, 0.2);
    /* margin: 0 0.3125rem 0 0.3125rem; */
    width: 100%;
}

/* .dashBoardMainSection .infoIconBlock.labelCard:nth-of-type(1){
    margin: 0 0.3125rem 0 0;
} */

.dashBoardMainSection .highChartMainSection .leftSec_full {
    width: 97%;
    height: 320px;
    float: left;
    box-shadow: 0px 4px 10px rgba(228, 229, 232, 0.20);
    /* box-shadow: 0px 4px 10px rgba(98, 119, 180, 0.2); */
    background-color: #fff;
    border: 1px solid #EFF2F8;
    border-radius: 1rem;
    padding: 1rem;
}

/* .dashBoardMainSection .infoIconBlock{
    width: 24.21%;
} */

.tableRowContainer .query_id_col {
    background-color: #FF2A2A;
    color: #FFFFFF;
    width: 100%;
    border-radius: 50px;
    padding: 8px 0;
    font-size: .68rem;
    text-align: center;
}

.tableRowContainer .todo_btn {
    background-color: transparent;
    color: royalblue;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
}

.table_header_section{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1rem;
}

.table_header_section .table_search_wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
}

/* ---------- Itinerary Proposal grid style ------------ */
.image_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    gap: 1.25rem;
}

.image_grid .image_content {
    height: 150px;
    cursor: pointer;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
}

.image_grid .image_content:hover {
    opacity: .9;
}

.image_grid .image_content.selected_image {
    border: .4rem solid #A4B4C1;
}

.image_grid .album_img {
    width: 100%;
    height: 100%;
}

/* ------------------ dialog new style added on top of existing style  ------Subhadip Roy/08072024------ */
.dialog_header {
    display: flex;
    padding: .8rem 1.5rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.875rem 1.875rem 0 0;
    background-color: #FFFFFF;
}

.dialog_header .heading {
    margin-left: 1.5rem;
    font-size: 1.2rem;
}

.dialog img._close_popup {
    margin-top: 0rem;
}

.dialog .dialog_main_section {
    padding: 0 0;
}

.dialog .pageInnerMainSection {
    border-radius: 0 0 1.875rem 1.875rem;
    padding-top: .5rem;
}

.dialog .accordion {
    padding: .5rem 1.25rem;
}

.dialog .accordion_header {
    font-size: 1rem;
    display: flex;
    gap: .68rem;
    align-items: center;
    white-space: nowrap;
    margin-right: .68rem;
    /* color: #d0d7ddb9; */
}

.dialog .accordion .formSection {
    margin-top: 0;
    color: #6d6d6d;
}

.dialog .accordion .formFieldContainer {
    padding-bottom: 0;
}



/* ---------------- modal medium size style----------------------- */
.modal_container {
    background-color: #6d6d6d;
    width: 100%;
    height: 100%;
    margin: 4rem;
}

.modal_md {
    background-color: #f7f7f7;
    width: 550px;
    /* height: 400px; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    overflow: hidden;
}

.modal_main {
    position: relative;
    height: 380px;
    overflow: auto;
}

.modal_main .textField,
.formFieldContainer .textField {
    height: 2.8rem;
}

.formFieldContainer textarea.textField {
    height: 7.625rem;
    overflow: hidden;
}

textarea.itinerary-textarea{
    resize: both;
}

.content-loading{
    font-weight: 500;
    font-size: 1.05rem;
    color: #868b93a1;
    user-select: none;
    
}


/* ---------------- COMMON STYLING ------------------- */

.popup_1200 {
    width: 75rem;
}

.pageInnerMainSection {
    padding: 1.5rem 2rem;
}

.generalFormSectionMain {
    padding: 1.25rem .8rem;
}

.crmProtectiveContainer .innerMainContainer {
    padding-top: 4.5rem;
    height: auto;
}

.headerContainer {
    width: calc(100% - 224px);
    height: auto;
    position: fixed;
    left: 224px;
    top: 0;
    background-color: #F7F9FD;
    border-bottom: 1px solid #E5E9F1;
    z-index: 1;
}

.label_right {
    float: right;
    color: #0064DD;
    text-decoration: underline;
    cursor: pointer;
}

.label_right:hover {
    color: #7096ff;
}

.label_right.disable {
    pointer-events: none;
}

.action-dropdown-list {
    padding: .625rem 0;
}

.action-dropdown-list button {
    width: calc(100% - 0.5rem);
    padding: .2rem .625rem;
    text-align: left;
}

.action-dropdown-list button:hover {
    background-color: #dddfeb79;
}

.divider {
    background-color: #d0d7ddb9;
    height: .05rem;
}

.sysAppActBtn.addBtn {
    width: 11rem;
    color: #7A7E86;
    /* background-color: #06a046; */
    background-color: #f6f9ff;
    background-image: url(../images/icon/accordian_plus_icon.svg);
}

.align_end {
    align-items: end;
}

/* vendor confirmation modal "hotel_comfirmation" is a special class to handle styles in hotel modal. */
.hotel_comfirmation select.textField {
    padding-left: 0.4rem !important;
}

.hotel_comfirmation .textField,
.react-tel-input .form-control.textField {
    /* padding-left: 1rem;
    padding-right: 1rem; */
    padding: 0 0.4rem;
}

.hotel_comfirmation .tableMainSection .tableMainContainer .tableRowContainer .tableColumn {
    float: left;
    min-height: 4rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: #6B6E73;
    font-size: 0.875rem;
    line-height: normal;
    padding: 0 0.5rem;
}

.hotel_comfirmation .tableMainSection .tableMainContainer .tableRowContainer .tableColumn {
    font-size: 0.75rem;
    padding: 0 0;
}

.hotel_comfirmation .formFieldContainer {
    padding: 0.5625rem 0.3rem;
}

.warranty {
    height: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #FFF;
}

.warranty.warranty_active {
    background-color: #C3FADF;
    color: #5BB288;

}

.warranty.warranty_expired {
    background-color: #FFE5E6;
    color: #E5252A;
}


.tox-tinymce {
    overflow: initial !important;
}

.message-block{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.message-block p{
    font-style: italic;
    font-size: 1rem;
    color: #6b6e739b;
}

.accordion-heading{
    font-size: 1.2rem;
}

.remove_link{
    /* width: auto; */
    font-size: 12px;
    color: #0064DD;
    text-decoration: underline;
    cursor: pointer;
}

.flex-col{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mt-24px{
    margin-top: 1.5rem;
}


/* ------------------------ proposal popup or modal style / 19-08-2024 ---------------------- */

.proposal_container {
    display: flex;
    flex-direction: column;
}

.proposal_header {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 600;
    border-bottom: .02rem solid #A4B4C1;
}

.proposal_header p {
    margin: 0 0;
    padding: 0 1rem;
    padding-bottom: .2rem;
}

.proposal_body .proposal_body_content {
    display: flex;
    justify-content: space-between;
    margin: .2rem 0;
    padding: 0 1rem;
    font-size: .9rem;
}

.proposal_container .switch {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 50px;
    width: 45px;
    height: 24px;
}

.switch input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.switch label {
    cursor: pointer;
    text-indent: -9999px;
    width: 100%;
    height: 100%;
    background: #c2c2c2;
    display: block;
    border-radius: 100px;
    position: relative;
}

.switch label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.switch input:checked+label {
    background: #006eff;
}

.switch input:checked+label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
}

.switch label:active:after {
    width: 25px;
}

.proposal_price_inc {
    margin-top: 1rem;
}

.proposal_price_inc>* {
    cursor: pointer;
    user-select: none;
    font-size: .9rem;
}




/* ------------------ Hotel Voucher Style / 23-08-2924 ---------------------- */
/* style={{ display: "flex", justifyContent: "center" }} */
.hotel_voucher_main {
    display: flex;
    justify-content: center;
}

.hotel_voucher_main .wrapper {
    width: 7.5in;
    height: 100%;
    background-color: #EBEBEB;
}



/* style={{ margin: ".3in .65in", padding: "1.5rem 2rem", height: "100%", backgroundColor: "#FFFFFF" }} */
.hotel_voucher_main .container {
    margin: .3in .65in;
    padding: 1.5rem 2rem;
    height: 100%;
    background-color: #FFFFFF;
}

.hotel_voucher_main .section_padding {
    padding: 1.1rem 0;
}

.hotel_voucher_main p {
    margin: 0 0;
}

.hotel_voucher_main .voucher_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.voucher_header .org_logo {
    height: 35px;
    width: 150px;
}

.voucher_header .org_details {
    font-size: .8rem;
    width: 12rem;
}

.voucher_header .org_details p {
    margin-bottom: .4rem;
}

.booking_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .75rem;
}

.booking_section .booking_table_col p:nth-of-type(2) {
    font-weight: 900;
    font-size: .8rem;
    max-width: 10rem;
}

.booking_section .booking_table_col .booking_status {
    background-color: #D2FFD2;
    color: #068806;
    padding: .2rem .3rem;
    font-size: .75rem;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 50px;
}

.hotel_section .hotel_details>p {
    font-size: 1rem;
    font-weight: 400;
}

.hotel_section .hotel_details .hotel_name {
    display: flex;
    gap: .5rem;
    align-items: end;
    
}

.hotel_section .hotel_details .hotel_name p:nth-of-type(1) {
    color: #3B385E;
    font-size: 1.3rem;
    font-weight: 800;
    margin: 0 0;
}

.hotel_section .hotel_details .hotel_name p:nth-of-type(2) {
    font-size: .85rem;
    font-weight: 900;
}

.hotel_section .hotel_contact {
    font-size: .8rem;
}

.hotel_section .hotel_reserve_time_section {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem;
}

.hotel_section .hotel_reserve_time_section div p:nth-of-type(1) {
    font-size: .9rem;
    font-weight: 900;
    color: #3C3C3C;
}

.hotel_section .hotel_reserve_time_section div p:nth-of-type(2) {
    font-weight: 900;
    color: #3C3C3C;
}

.hotel_section .hotel_reserve_time_section div p:nth-of-type(3) {
    font-size: .85rem;
    font-weight: 400;
    color: #4A4A4F;
}

.hotel_section .hotel_reservation {
    font-size: .8rem;
    margin-top: 2.2rem;
    color: #514D4B
}

.hotel_reservation>p>span {
    font-weight: 900;
    color: #4A4A4A;
}

.button_group {
    display: flex;
    /* justify-content: end; */
}

/** query **/
.table-container {
    width: 100%;
    overflow: auto;
    position: relative;
}

/* .res-testing {
    width:100%;
    
    
} */

/* .tableMainSection .tableMainContainer .tableRowContainer .fixed-content {
    padding: 0;
    position:sticky;
    right:0;   
} */
/* .tableRowContainer .tableColumn.fixed-content button {
        width: 100%;
        margin: 0;
    } */

/** Itinerary Service Dialog **/
.dialog .dialog-inner-section {
    padding: .5rem 2rem;
    overflow: auto;
}

/** eof Itinerary Service Dialog **/

@media print {
    body {
        -webkit-print-color-adjust: exact;
        /* Chrome, Safari */
        print-color-adjust: exact;
        /* Firefox */
    }

    #download-service-voucher {
        /* background-color: #000000; */
        padding-bottom: 0;
        margin: 0 0;
        height: auto;
        print-color-adjust: exact;
    }

    /* .itinerary_details_1 {
        page-break-inside: avoid;
        page-break-after: always;
        page-break-before: auto;
    } */

    #report-download-btn {
        display: none;
    }

    .report .report_content {
        margin: 0 0;
    }

    /* Example: Ensure graphics in certain elements are always visible */
    /* Force graphics to be displayed */
    /* .print-graphic {
        display: block !important;
    } */

    @page {
        size: A4;
        /* width: 8.27in; */
        /* margin: 1.3rem .5rem; */
        margin: 1.5rem 0;
    }
}